@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

.periksa {
  position: relative;
  width: 100%;
  background-color: #f9fbff;
  min-height: 100vh;
  overflow: hidden;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*padding: 0rem 5rem;*/
  /*transition: all 0.2s 0.7s;*/
  overflow: hidden;
}

.periksa-cek .periksabtn button{
  width: 350px;
  height: 52px;
  align-content: center;
  background-color: #9d6cae;
  border: #745ea9;
  outline: #6e5ca8;
  color: #fff;
  border-radius: 49px;
  font-weight: 700;
  margin: 15px 0;
  transition: 0.5s;
  cursor: pointer;
  display: grid;
  text-decoration: none;
}

.periksabtn button:hover {
  background-color: #745ea9;
  border-radius: 49px;
}

/*.periksa .content button{
  background-color: #9d6cae;
  border-bottom-right-radius: 49px;
  border-top-right-radius: 49px;
  text-decoration: none;
  text-align: center;
  border: 1px #745ea9;
  color: #fff;
  padding: 5px 30px;
  text-align: center;
  display: inline-block;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 25px;
}

.content button :hover {
  background-color: #745ea9;
}*/

.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.periksa:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2500px;
  top: -10%;
  left: 58%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #6e5ca8 25%, #9d6cae 75%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
}

.periksa-cek {
  position: absolute;
  top: 45%;
  transform: translate(-50%, -50%);
  left: 30%;
  width: 50%;
  /*transition: 1s 0.7s ease-in-out;*/
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

.prkimage {
  width: 35%;
  margin-left: 65%;
  margin-top: 3.5%;
  position: absolute;
}

/*score*/

.top {
  text-align: center;
  color: #745ea9;
  font-weight: 600;
}

.top .title {
  margin-bottom: 10%;
}

.btn-top {
  width: 250px;
  height: 48px;
}

.bottom {
  color: #745ea9;
  padding-top: 6%;
  width: max-content;
}

.bottom h3 {
  padding-bottom: 5%;
  padding-top: 10%;
}

.skill {
  width: 160px;
  height: 160px;
  /*background: cornflowerblue;*/
  position: relative;
  margin-left: 32%;
}

.outer {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  padding: 20px;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15), -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.inner {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2), inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7), -0.5px -0.5px 0px rgba(255, 255, 255, 1), 0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
}

#number {
  font-weight: 600;
  font-size: 45px;
  color: #745ea9;
}

circle {
  fill: none;
  stroke: url(#GradientColor);
  stroke-width: 20px;
  stroke-dasharray: 600;
  stroke-dashoffset: 600;
  animation: anim linear forwards;
}

svg {
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes anim {
  100% {
    stroke-dashoffset: 165;
  }
}

.status-membaik {
  border-radius: 2rem;
  padding: 0.4rem;
  text-align: center;
  background-color: #9acf9c;
  color: #fff;
  margin-top: 20%;
}
.status-memburuk {
  border-radius: 2rem;
  padding: 0.4rem;
  text-align: center;
  background-color: #ec6875;
  color: rgb(255, 244, 244);
  margin-top: 20%;
}

@media screen and (min-width: 671px) {
  .periksa-cek .periksabtn button{
    margin-left: 4%;
  }

  .status {
    margin-left: 34%;
  }
}
/*MOBILE*/

@media screen and (max-width: 670px) {
  /*.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
  }*/

  .periksa:before {
    display: none;
  }

  .periksaimg img {
    display: none;
  }

  .periksa-cek {
    width: 35rem;
    height: 35rem;
    left: 50%;
    margin-top: 15%;
  }

  .skill {
    margin-left: 22%;
  }

  .bottom {
    font-weight: 10;
  }

  .bottom h3{
    justify-content: center;
    font-size: 13pt;
  }

  .status {
    margin-left: 26%;
  }
}