@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff;
}

body,
input,
textarea,
button {
  font-family: "Poppins", sans-serif;
}

.biodata {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
}

/* Contact.js */

.backbio button {
  background-color: #9d6cae;
  border-bottom-right-radius: 49px;
  border-top-right-radius: 49px;
  text-decoration: none;
  text-align: center;
  border: 1px #745ea9;
  color: #fff;
  padding: 5px 30px;
  display: inline-block;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 600;
  font-size: 25px;
}

.backbio button:hover {
  background-color: #745ea9;
  color: #fff;
}

.kotak {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.297);
  border-radius: 13px;
  padding-top: 3%;
}

.formbio .kotak > h1 {
  margin-bottom: 30px;
  color: #9d6cae;
}

.formbio .kotak > input,
textarea {
  padding: 20px;
  border-radius: 3px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  margin-bottom: 20px;
  border: 1px solid lightgray;
  /* border: none; */
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none; 
}

.formbio .kotak > input:focus,
textarea:focus {
  border: 1px solid rgb(0, 0, 196);
}

.formbio .kotak > textarea {
  height: 150px;
  max-width: 400px;
  min-height: 100px;
}

.formbio .kotak > label {
  padding-bottom: 10px;
  color: rgb(0, 0, 32);
  font-weight: bold;
}

.formbio .kotak > button {
  padding: 15px;
  width: 132px;
  height: 70px;
  border: none;
  background-color: #9d6cae;
  font-weight: 500;
  font-size: 20px;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
  margin-bottom: 5%;
}

.formbio .kotak > button:hover {
  background-color: #745ea9;
}

@media screen and (min-width: 670px) {
  .formbio .kotak > input,
  textarea {
    width: 30em;
  }

  .kotak {
    margin-top: 1%;
    margin-bottom: 3%;
  }
}

@media screen and (max-width: 670px) {
  .formbio .kotak > label {
    width: 240px;
    text-align: center;
  }
  
  .riwayat::-webkit-input-placeholder{
    font-size: 8pt;
  }
  .riwayat:-moz-input-placeholder{
    font-size: 8pt;
  }
  .riwayat:-ms-input-placeholder{
    font-size: 8pt;
  }
}